import React, { FunctionComponent } from 'react';
import gql from 'graphql-tag';

import { getEventLogger, BRAND_NAME, SWITCHES } from 'z-frontend-app-bootstrap';
import {
  useMicroTrials,
  useMicroTrialSubscription,
  BannerContent,
  Features,
  MicroTrialPromoSideBanner,
} from 'z-frontend-microtrials';
import { growthUtils, DialogsManager, DialogProps } from 'z-frontend-layout';
import { checkPermission, usePermissions, useSwitches, Query } from 'z-frontend-network';
import { Flex } from 'zbase';
import { v3BasePlanSkus, GREEN_COHORT_TYPE } from 'z-frontend-hr-components';

import { PayrollPromo } from './payroll-promo/PayrollPromo';
import QuickLinks, { QuickLink } from './QuickLinks';
import { QuickLinksQuery } from '../gqlTypes';
import { DashboardRole } from '../types';
import { CheckoutPackageProductSku, ContactAdvisorType, ZAppActionUrl } from '../../schema/schemaTypes';
import AdvisorModal from './quick-links/AdvisorModal';
import { CONTRACTOR_PAYMENTS, IOM } from '../constants';

const quickLinksQuery = gql`
  query QuickLinksQuery($skipActiveBillingDetails: Boolean!) {
    dashboard {
      id
      isTrialCompany
      features
      switches
      company {
        id
        salesforceAccount {
          id
          cohortType
          owner {
            role
            email
          }
        }
      }
      isMTAPartnerUser
    }
    zAppActionUrls {
      id
      title
      actionUrl
      role
    }
    activeBillingDetails @skip(if: $skipActiveBillingDetails) {
      version
      planDetails {
        name
      }
    }
  }
`;

function isActionVisible(actionRole: string, dashboardRole: DashboardRole): boolean {
  if (actionRole !== 'ADM' && actionRole !== 'EMP') {
    return true;
  }

  return actionRole === dashboardRole;
}

function getLinkAttributes(key: string) {
  // data-testid provided later
  return {
    className: `js-walkme-quickLink-${key}`, // match Ember
  };
}

const getPriorityLinks = (role: DashboardRole, dashboard: QuickLinksQuery.Dashboard): QuickLink[] => {
  const links = [];
  const { isTrialCompany, switches } = dashboard;

  if (role === DashboardRole.admin && !isTrialCompany && switches?.benefits_shopping_page) {
    links.push({
      title: 'Shop Benefits',
      href: '/app/benconnect/#/shopping',
      target: '_blank',
    });
  }

  return links;
};

function getNewSectionWithConditionalLinks(role: DashboardRole, dashboard: QuickLinksQuery.Dashboard): QuickLink[] {
  const { features, switches, isMTAPartnerUser, company } = dashboard;
  const dashboardUrl = isMTAPartnerUser ? `/dashboard/?company=${company.id}/#/` : `/dashboard/#/`;
  const links = [];

  if (switches.covid_support_banner) {
    links.push({
      title: 'COVID-19 FAQs',
      href: 'https://help.zenefits.com/COVID-19_Resources/',
    });
  }

  if (switches.covid_ppp_report && role === DashboardRole.admin && features.includes('pyp')) {
    links.push({
      title: 'Paycheck Protection Program (PPP) Loan',
      href: '/dashboard/#/payroll/reports-beta/covid-loan-report',
    });
  }

  if (switches.in_product_promo_telehealth) {
    links.push({
      title: 'Telehealth',
      href: 'https://healthinsurance.pxf.io/5vAG9',
      onClick: () => {
        getEventLogger().log('in_product_promo_telehealth', {
          sourceOfClick: 'quicklink',
        });
      },
      target: '_blank',
    });
  }

  if (switches.covid_zywave && role === DashboardRole.admin) {
    links.push({
      title: 'Zywave Learning Coronavirus Prevention & Response Training',
      href: `${dashboardUrl}integrations?view=172.com.zywave`,
    });
  }

  return links;
}

function currentUrlWithHash(hash: string) {
  const url = new URL(window.location.href);
  url.hash = hash;
  return url.toString();
}

function getLinks(role: DashboardRole, zAppActionUrls: ZAppActionUrl[]): QuickLink[] {
  const links = zAppActionUrls
    .filter(action => action.id && action.title && action.actionUrl && action.role)
    .filter(action => isActionVisible(action.role, role))
    .map(action => {
      // zAppActionUrls just need appended to the current url
      return {
        ...action,
        actionUrl: null,
        href: currentUrlWithHash(`/${action.actionUrl}`),
      } as QuickLink;
    });
  return links;
}

export const QuickLinksContainer: FunctionComponent<{ role: DashboardRole }> = ({ role }) => {
  const { permissions } = usePermissions();
  const switches = useSwitches();
  const isFullCompanyAdmin = checkPermission(permissions, 'is_full_company_admin');
  const isSelfServeUpgradeDisabled = switches[SWITCHES.disableSelfServeUpgrade];
  const showUpgradePlanQuickLink = isFullCompanyAdmin && !isSelfServeUpgradeDisabled;

  const {
    loading: microTrialSubscriptionLoading,
    isMicroTrialFeatureActive,
    isMicroTrialFeatureExpired,
  } = useMicroTrialSubscription();
  const { microTrial } = useMicroTrials();
  const showPayrollPromo = switches[SWITCHES.optOutFreeTrialPayrollDiscount];
  const showNewMarketplace = switches?.new_marketplace_enable;
  const enableAppDirectory = switches?.app_directory_enable && showNewMarketplace;

  let showMicroTrialPromoBanner = false;
  if (!microTrialSubscriptionLoading) {
    showMicroTrialPromoBanner =
      role === DashboardRole.admin &&
      isFullCompanyAdmin &&
      !(isMicroTrialFeatureActive(Features.Talent) || isMicroTrialFeatureExpired(Features.Talent)) &&
      !!microTrial;
  }

  const getConditionalLinks = (
    role: DashboardRole,
    dashboard: QuickLinksQuery.Dashboard,
    ultimateDialog: DialogProps,
    hrDialog: DialogProps,
    payrollDialog: DialogProps,
    activeBillingDetails: QuickLinksQuery.ActiveBillingDetails,
  ): QuickLink[] => {
    const { features, switches, isTrialCompany } = dashboard;
    const links = [];

    if (role === DashboardRole.admin) {
      if (showUpgradePlanQuickLink) {
        links.push({
          title: 'Upgrade Plan and Available Add-ons',
          href: '/app/checkout/#/upgrades/wizard/',
          ...getLinkAttributes('upgradePlan'),
        });
      }

      const baseProductSku = activeBillingDetails?.planDetails?.name;
      const isV3BasePlan = v3BasePlanSkus.includes(baseProductSku as CheckoutPackageProductSku);
      const isGreenCohort = dashboard?.company?.salesforceAccount?.cohortType === GREEN_COHORT_TYPE;
      const isV4GreenCohort = activeBillingDetails?.version === 4 && isGreenCohort;
      // Only show "Modify or Cancel Your Plan" if company is on a v3 base plan or v4 green cohort
      const showModifyOrCancelYourPlan = isV3BasePlan || isV4GreenCohort;

      if (showModifyOrCancelYourPlan) {
        const csmOwnerRole = 'Customer Success Manager';
        const longtailRenewalEmail = 'Tz-Renewal_Specialists-SF@Trinet.com';

        const isCsm = dashboard?.company?.salesforceAccount?.owner?.role === csmOwnerRole;
        const email = (isCsm && dashboard?.company?.salesforceAccount?.owner?.email) || longtailRenewalEmail;

        links.push({
          title: 'Modify or Cancel Your Plan',
          onClick: () => {
            window.open(`mailto:${email}`, '_blank');
          },
        });
      }

      if (features.includes('ultimateAdvisor')) {
        links.push({
          title: `Contact a ${BRAND_NAME} Ultimate Advisor`,
          onClick: () => {
            ultimateDialog.open();
          },
          ...getLinkAttributes('contactUltimateAdvisor'),
        });
      } else if (features.includes('hrSpecialist')) {
        links.push({
          title: 'Contact an HR Advisor',
          onClick: () => {
            hrDialog.open();
          },
          ...getLinkAttributes('contactHrSpecialist'),
        });
      } else if (features.includes('pypAdvisor')) {
        links.push({
          title: 'Contact a Payroll Advisor',
          onClick: () => {
            payrollDialog.open();
          },
          ...getLinkAttributes('contactPypAdvisor'),
        });
      }
    }

    if (isTrialCompany) {
      links.push({
        title: 'Buy Now',
        href: growthUtils.getBuyLink(),
        ...getLinkAttributes('trialCheckoutNow'),
      });

      if (switches.enable_trial_guide) {
        links.push({
          title: 'Getting Started Guide',
          href: currentUrlWithHash('/guide'),
          ...getLinkAttributes('gettingStarted'),
        });
      }
    }

    if (switches.skilljar_quicklink) {
      links.push({
        title: 'Training',
        href: 'https://training.zenefits.com/auth/login?next=%2F',
        ...getLinkAttributes('training'),
      });
    }

    if (showNewMarketplace) {
      links.push({
        title: 'Go to Marketplace',
        href: currentUrlWithHash('/oauth2/embedded-authorize/marketplace/').replace('/dashboard/#/', '/'),
        badge: 'New',
      });
    }

    if (switches['sidecar_health-separated_employee'] || switches['sidecar_health-separated_employee']) {
      links.push({
        title: 'Affordable Coverage - Sidecar Health',
        href: 'https://zen.fit/sidecarhealth',
        ...getLinkAttributes('sidecar'),
      });
    }

    links.push({
      title: 'People Ops Book',
      href: 'https://www.amazon.com/People-Operations-Automate-Experience-Workforce/dp/1119785235',
      target: '_blank',
    });

    return links;
  };

  return (
    <Query<QuickLinksQuery.Query>
      query={quickLinksQuery}
      variables={{ skipActiveBillingDetails: !isFullCompanyAdmin }}
      handleLoading={false}
      isBackgroundQuery
    >
      {({ data, loading }) => {
        if (loading || !role) {
          return null;
        }

        const isIOM = data?.dashboard?.features?.includes(IOM);
        const isContractorPaymentsCompany = data?.dashboard?.features?.includes(CONTRACTOR_PAYMENTS);
        const isCpWithIomCompany = isIOM && isContractorPaymentsCompany;
        const isContractorOnly = isContractorPaymentsCompany && !isIOM;

        // for CP+IOM company - show App Directory, Benefits Preview section
        return (
          !isContractorOnly && (
            <DialogsManager
              dialogsCount={3}
              render={dialogs => {
                const [ultimate, hr, payroll] = dialogs;

                /**
                 * priorityLinks, links, and conditionalLinks are concatenated into one array.
                 * priorityLinks appear before links and conditionalLinks in the array.
                 */
                const priorityLinks = getPriorityLinks(role, data.dashboard);
                const links = getLinks(role, data.zAppActionUrls);
                const conditionalLinks = getConditionalLinks(
                  role,
                  data.dashboard,
                  ultimate,
                  hr,
                  payroll,
                  data.activeBillingDetails,
                );

                const conditionalLinksForNewSection = getNewSectionWithConditionalLinks(role, data.dashboard);
                const hasNewConditionalLinks = conditionalLinksForNewSection.length > 0;

                let allLinks = priorityLinks.concat(links, conditionalLinks);
                const appDirectory = showNewMarketplace ? 'Go to Marketplace' : 'App Directory';
                if (isIOM && !isCpWithIomCompany) {
                  allLinks = allLinks.filter(
                    l => !['Documents', 'Training', 'Edit My Profile', appDirectory, 'App Directory'].includes(l.title),
                  );
                }

                if (isCpWithIomCompany) {
                  allLinks = allLinks.filter(l => [appDirectory].includes(l.title));
                }

                if (showNewMarketplace && !isIOM) {
                  allLinks = allLinks.filter(l => l.title !== 'App Directory');
                }

                if (enableAppDirectory && !isIOM) {
                  allLinks = allLinks.concat(links.filter(l => ['App Directory'].includes(l.title)));
                  const linkIndex = allLinks.findIndex(obj => obj.title === 'App Directory');
                  if (linkIndex >= 0) {
                    allLinks[linkIndex].title = 'App Directory (legacy)';
                  }
                }

                return (
                  <Flex direction={['row', null, 'column']} mt={[5, null, 6]}>
                    <QuickLinks
                      w={hasNewConditionalLinks ? [1 / 2, null, 1] : 1}
                      heading="Quick Links"
                      links={allLinks}
                    />
                    {!isCpWithIomCompany && hasNewConditionalLinks && (
                      <QuickLinks
                        w={[1 / 2, null, 1]}
                        heading="COVID-19 Resources"
                        links={conditionalLinksForNewSection}
                      />
                    )}
                    {showPayrollPromo && <PayrollPromo />}
                    <AdvisorModal dialog={ultimate} />
                    <AdvisorModal dialog={hr} contactType={ContactAdvisorType.hr} />
                    <AdvisorModal dialog={payroll} contactType={ContactAdvisorType.payroll} />
                    {showMicroTrialPromoBanner && (
                      <MicroTrialPromoSideBanner microTrial={microTrial} bannerContent={BannerContent} />
                    )}
                  </Flex>
                );
              }}
            />
          )
        );
      }}
    </Query>
  );
};

export default QuickLinksContainer;
